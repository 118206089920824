<template>
    <div class="nav-main">
        <div class="nav-head">
            <div style="display: flex;justify-content: space-between;">
                <a @click="clickLog" class="logo"><img :src="logoImg" /></a>
                <div class="hamburger" @click="isMobileNavView=true">
                    
                </div>
            </div>
            <div class="nav-menu">
                <div v-for = "(m1,i) in menu" @mouseover="clickLog" :key="i">
                    <router-link v-bind:to="m1.link">
                    <div class="nav-child">
                        <div class="nav-icon">
                            <img :src="require(`@/assets/${m1.icon}`)" />
                        </div>
                        <div class="nav-content">
                            <div class="title_kr">{{m1.kr}}</div>
                            <div class="title_en">
                                {{m1.en}}
                            </div>
                        </div> 
                    </div>
                    </router-link>
                </div>
            </div>
            <div class="nav-user">
                <div v-if="userlog==null">
                    <SignIn v-if="isSignInView" @close-modal="isSignInView=false"/>
                    <v-icon>mdi-account</v-icon>
                    <v-btn class="btn-margin" depressed small color="gray" @click="isSignInView=true">
                        로그인
                    </v-btn>
                    <v-btn class="btn-margin" depressed small dark color="purple" @click="doSignUp()">
                        회원가입
                    </v-btn>
                </div>
                <div v-if="userlog!=null" @click="clickLogout">
                    <router-link to="/">
                        <div style="font-size:10px">가나다라가나다라가나다라가나다라마마마마님</div>
                        <v-icon>mdi-account</v-icon>로그아웃
                    </router-link>
                </div>
            </div>
            <transition name="slide-up" apper>
                <MobileNav v-if="isMobileNavView" @close-modal="isMobileNavView=false" v-bind:menus="menu"/>
            </transition>
        </div>
    </div>
</template>
<script>
import SignIn from "../modal/SignIn.vue";
import MobileNav from "../modal/MobileNav.vue";

export default({
    components:{
        SignIn,
        MobileNav,
    },
    data(){
        return{
            userlog:this.$session.get("user"),
            isSignInView:false,
            isMobileNavView:false,
            navfooter:false,
            menu:[
                {
                    kr:'홈',
                    en:'Home',
                    sub:[
                        '메인'
                    ],
                    link:'home',
                    icon:'home.png',
                },
                {
                    kr:'소개',
                    en:'About me',
                    sub:[
                        '프로필(profile)',
                        '역사(history)',
                    ],
                    link:'aboutme',
                    icon:'cv.png',
                },
                {
                    kr:'소통',
                    en:'Communicate',
                    sub:[
                        '문의(Inquire)',
                        '자유게시판(Free)'
                    ],
                    link:'communicate',
                    icon:'noticeboard.png',
                },
                {
                    kr:'아카이브',
                    en:'Archive',
                    sub:[
                        '알고리즘(Algorithm)',
                        '자격증(Certification)',
                        '독후감(Book Report)'
                    ],
                    link:'archive',
                    icon:'archive.png',
                },
            ],
            logoImg:require("../assets/logo.png"),
            arrow: 'arrow-down.png',
            cross: 'cross.png',
            whiteList:['/'],
        }
    },

    methods:{
        clickLog(){
            console.log('test');
        },
        clickLogout(){
            this.$session.destroy();
            if(!this.whiteList.includes(window.location.pathname)){
                this.$router.push('/');    
            }
            this.$router.go();
        },
        menuToggle(){
            this.navfooter= !this.navfooter
        },
        doSignUp(){
            if('/signup' != (window.location.pathname)){
                this.$router.push('/signup');
            }
            this.$emit('close-modal')
        }
    },

})
</script>
<style scoped>
.slide-up-enter{
    transform: translateX(60vw);
    opacity: 0;
}
.slide-up-enter-active,
.slide-up-leave-active{
    transition: all 1s ease;
}
.slide-up-leave-to{
    transform: translateX(60vw);
    opacity: 0;
}

.nav-main>div{
    display:flex;
    font-size: 20px;
    justify-content: space-between;
}
.logo>img{
    opacity:1;
    width: 100px;
}
.nav-menu{
    display: flex;
}
.menu-toggle{
    display: flex;
    text-align: right;
    flex-direction: row-reverse;
}
.nav-menu>div{
    width:8vw;
    text-align: center;
    margin:0 0.5vw;
}
.nav-menu>div>a{
    text-decoration : none;
}
.nav-main>.nav-footer{
    width:100vw;
    display: flex;
    justify-content:center;
}
.title_kr{
    font-size: 25px;
}
.title_en{
    font-size: 15px;
}

.nav-icon>img{
    width: 40px;
    margin: 10px;
}
.nav-content{
    width:100%;
}
.btn-margin{
    margin:0 3px;
}
.nav-user{
    width:210px;
    text-align: right;
    padding:1.5vh 0;
}
.nav-child{
    display: flex;
}
.hamburger{
    display:none;
}
@media screen and (max-width:2020px){
    .nav-menu>div{
        width:12vw;
        text-align: center;
        margin:0 0.5vw;
    }
}
@media screen and (max-width:1340px){
    .nav-menu>div{
        width:15vw;
        text-align: center;
        margin:0 0.5vw;
    }
}
@media screen and (max-width:1070px){
    .nav-menu{    
        display: none;
        /* flex-wrap:wrap; */
    }
    .nav-user{
        display: none;
    }
    .hamburger{
        width: 58px;
        height: 58px;
        background-size:cover;
        background-position: center;
        background-image: url("../assets/hamburger.png");
        display: block;
    }
    .nav-head{
        flex-direction: column;
    }
}
</style>