<template>

    <div class="modal">
        <div class="modal-card">
            
            <img class="cross" @click="$emit('close-modal')">
            <div class="nav-ment">
                안녕하세요 {{name}}님,
                <div v-if="userlog==null">
                    로그인 하시면 보다 많은<br/>
                    자료를 이용하실 수 있을 지도?<br/>
                    <v-btn class="btn-margin" depressed small color="gray" @click="isSignInView=true">
                        로그인
                    </v-btn>
                    <v-btn class="btn-margin" depressed small dark color="purple" @click="doSignUp()">
                        회원가입
                    </v-btn>
                </div>
                <SignIn v-if="isSignInView" @close-modal="isSignInView=false"/>
            </div>
            <div v-for = "(m1,i) in this.menus" :key="i" @click="$emit('close-modal')">
                <router-link v-bind:to="m1.link">
                    <div class="title">{{m1.kr}}</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import SignIn from "../modal/SignIn.vue";

export default {
    name : 'MobileNav',
    components:{
        SignIn,

    },
    props:{
        menus: {},
    },
    data(){
        return{
            userlog:this.$session.get("user"),
            name:"방랑자",
            isSignInView: false,
        }
    },
    methods:{
        doSignUp(){
            if('/signup' != (window.location.pathname)){
                this.$router.push('/signup');
            }
            this.$emit('close-modal')
        }
    }

}
</script>

<style scoped>
.cross{
    position: fixed;
    top: 20px;
    right: 20px;
    width: 58px;
    height: 58px;
    background-size:cover;
    background-position: center;
    background-image: url("../assets/cross.png");
}
.modal{
    top: 0px;
    left: 0px;
    position:fixed;
    width: 100vw;
    height: 100vw;
}
.modal-card{
    
    top: 0px;
    left: 0px;
    position:relative;
    width: 100vw;
    background-color: white;
    height: 100vh;
}
.title{
    height: 50px;
    line-height: 50px;
    border-style:solid;
    border-width: 1px;
    border-color:#0C0;
    padding: 0 0 0 15px;
    
}
.modal-card>div>a{
    text-decoration : none;
}
.nav-ment{
    font-size:15px;
    width:100vw;
    background-color: yellow;
    height: 150px;
    padding: 20px;
}
.modal{
    display:none;
}
/* .slide-up-enter{
    transform: translateX(-100px);
    opacity: 0;
}
.slide-up-leave-active,
.slide-up-enter-active{
    transition: all 1s ease;
}

.slide-up-leave-to{
    transform: translateX(-100px);
    opacity: 0;
}
.slide-up-move{
    transition: transform 0.5s ease-out;
} */

@media screen and (max-width:1070px){
    .modal{
        display: block;
    }

}

</style>