<template>
  <v-app id="container-main" >
    <div id="header" :class="{op:(scrollY<scrollFlag&&!mouseover)}" @mouseover="mouseover=true" @mouseleave="mouseover=false" ref="rheight">
      <Nav/>
    </div>
    <div id="body">
      <!-- <SignUp/> -->
      <router-view/>
    </div>
    <div id="footer" :class="{displaynone:scrollY<scrollFlag}">
      footer
    </div>
    <div id="scroll-top" @click="onScrollTop" :class="{displaynone:scrollY<scrollFlag}">
      <img style="width:40px" :src="require(`@/assets/arrows-up.png`)"/>
    </div>


  </v-app>
</template>

<script>
import Nav from './components/Nav.vue';
// import SignUp from './modal/SignUp';
// import {Hooper, Slide} from 'hooper';

export default {
  name: 'App',

  components: {
    // SignUp,
    Nav,
    // Hooper,
    // Slide,
  },
  data(){
    return {
      mouseover: false,
      scrollFlag: 40,
      scrollY:0,
      MaxHeight:0,
      BrowserHeight:0,
      // baseUrl:"http://192.168.0.8:9999",
      //baseUrl: 'http://yelime.pe.kr:9999';
    }
    //
  },
  mounted() {
    window.scrollTo(0,0);
    document.addEventListener('scroll',this.scrollEvents);
    this.BrowserHeight = document.documentElement.scrollHeight;
  },
  beforeUnmount() {
    document.removeEventListener('scroll',this.scrollEvents);
  },
  unmounted() {
  },
  updated(){
  },
  methods:{
    onScrollTop() {
      window.scrollTo(0,0);
      // var val = this.$refs.rheight.scrollHeight;
      // console.log(val);
      console.log(this.MaxHeight);
      console.log(this.BrowserHeight);
    },
    scrollEvents() {
        const headerState = this.headerState;
        this.headerScrollEvent(headerState);
        // 특정페이지에는 이 부분의 주석을 해제해 준다.
        // this.navScrollEvent();
    },
    headerScrollEvent(headerState) {
      // 현재 스크롤 탑
      const beforeScrollTop = this.currentScrollValue;
      // 스크롤 후의 탑
      const afterScrollValue = document.documentElement.scrollTop;
      this.MaxHeight = document.documentElement.scrollHeight;
      // this.BrowserHeight = document.documentElement.clientHeight;

      // 추후에 nav가 생기더라도 수정해줄 필요가 없다.
      if (headerState) {
        if (afterScrollValue > beforeScrollTop) {
          this.currentScrollValue = beforeScrollTop;
          this.scrollY = this.currentScrollValue;
          return
        } else {
          this.currentScrollValue = beforeScrollTop;
          this.scrollY = this.currentScrollValue;
          return
        }
      } else {
        if (afterScrollValue > beforeScrollTop) {
          this.currentScrollValue = afterScrollValue;
          this.scrollY = this.currentScrollValue;
          return
        } else {
          this.currentScrollValue = afterScrollValue;
          this.scrollY = this.currentScrollValue;
          return
        }
      }
    },
  },
};
</script>
<style>
html{
  overflow-y: auto !important;
}
#container-main::-webkit-scrollbar {
  display: none; 
}
#container-main{
  max-height: 100vh;
	overflow-y:scroll;
  background-color:gray;
}
/* #container-main>div:nth-child(odd){} */
#header{
    z-index: 3;
    position:fixed;
    background-color:yellow;
    width:100vw; 
}
#header>div{
  padding: 10px 15vw;
  width:100vw; 
}
.displaynone{
  display: none;
}
.op{
    opacity: 0.6;
}
#scroll-top{
  position: fixed;
  right: 10vw;
  bottom: 0px;
  margin: 5px;
}
#body>div{
    background-color: black;
}

@media screen and (max-width:1650px){ 
  
  #header>div{
    padding: 10px 5vw;
  }
}

@media screen and (max-width:1070px){
  .op{
    opacity: 1;
  }

}

/* smartphones, touchscreens */
@media (hover: none) and (pointer: coarse) {
  .op{
    opacity: 1;
  }
}
/* stylus-based screens */
@media (hover: none) and (pointer: fine) {
}
/* Nintendo Wii controller, Microsoft Kinect */
@media (hover: hover) and (pointer: coarse) {
}
/* mouse, touch pad */
@media (hover: hover) and (pointer: fine) {
}

</style>