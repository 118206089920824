<template>
    <div class="modal">
        <div class="overlay" @click="$emit('close-modal')"></div>
        <div class="modal-card">
            <div>
                로그인
            </div>
            <v-form ref="form">
                <div>
                    <v-text-field v-model="user.id" :rules="idRule" label="아이디" type="id" prepend-icon="mdi-identifier"/>
                </div>
                <div>
                    <v-text-field v-model="user.pw" :rules="pwRule" label="비밀번호" type="password" prepend-icon="mdi-lock"/>
                </div>
            </v-form>
            <div>
                <v-btn depressed small dark color="green" @click="doSignIn()"><v-icon>mdi-keyboard-return</v-icon>로그인</v-btn>
                <v-btn depressed small dark @click="$emit('close-modal')"><v-icon>mdi-close</v-icon>닫기</v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'

export default {
    data(){
        return{
            whiteList: ['/'],
      
            user:{
                id:"",
                pw:"",
            },
            idRule:[
                v=> !!v||'아이디가 입력되지 않았습니다.'
            ],
            pwRule:[
                v=> !!v||'비밀번호가 입력되지 않았습니다.'
            ],
        }
    },
    methods: {
        doSignIn(){
            const validate = this.$refs.form.validate();
            if(validate){
                axios.post(`${this.$baseUrl}/user/signin`,this.user)
                .then((res)=>{
                    if(res.data == "loginfail"){
                        alert("id혹은 pw가 잘못되었습니다. 다시 입력해주세요.")
                    }
                    else {
                        this.$session.start()
                        this.$session.set('user',res.data)
                        if(!this.whiteList.includes(window.location.pathname)){
                            this.$router.push('/');
                        }
                        else {
                            this.$router.go();
                        }
                        this.$emit('close-modal')
                    }
                })
            }

        },
    }

}
</script>


<style scoped>
.modal,
.overlay{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top:0;
    z-index:10;
    text-align: left;
}
.overlay{
    opacity: 0.5;
    background-color:black;
}
.modal-card{
    position:relative;
    max-width: 40%;
    margin:auto;
    margin-top: 100px;
    padding:20px;
    background-color: white;
    min-height: 500px;
    z-index:10;
    opacity:1;
}
.modal{
    position: absolute;
}

@media screen and (max-width:1070px){
    .modal-card{
        width: 95vw;
        max-width: 400px;
    }

}

</style>