import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/index.js'
import VueSession from "vue-session"
import VueAgile from "vue-agile"

Vue.config.productionTip = false;
// Vue.prototype.$baseUrl = "http://192.168.0.8:9999";
Vue.prototype.$baseUrl = 'http://yelime.pe.kr:9999';
var options = {
  // persist: true
};
Vue.use(VueSession, options, VueAgile);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
