import Vue from 'vue'
import VueRouter from 'vue-router'

// import axios from 'axios'
// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Intro',
        component:() => import('../components/Intro.vue')
    },
    {
        path: '/creation',
        name: 'creation',
        component:() => import('../components/Creation.vue')
    },
    {
        path: '/aboutme',
        name: 'aboutme',
        component:() => import('../components/AboutMe.vue')
    },
    {
        path: '/archive',
        name: 'archive',
        component:() => import('../components/Archive.vue')
    },
    {
        path: '/communicate',
        name: 'communicate',
        component:() => import('../components/Communicate.vue')
    },
    {
        path: '/study',
        name: 'study',
        component:() => import('../components/Study.vue')
    },
    
    {
        path: '/signup',
        name: 'signup',
        component:() => import('../components/SignUp.vue')
    },
    {
        path: '/home',
        name: 'home',
        component:() => import('../components/Home.vue')
    },
    { 
        path: '*', 
        component:() => import('../components/NotFound.vue'), 
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

export default router